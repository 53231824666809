import { of, throwError } from 'rxjs';
import { catchError, tap, timeout } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { TranslateText } from '@player/shared/pipes/translate-text.pipe';
import { PlayerApi } from '@player/shared/services/player-api.service';
import {
  InterviewData,
  InterviewMessageData,
  RootQuestionData,
  WhyFinderRequestData,
} from '@player/shared/models/player.model';
import { QuestionData } from '@shared/models/survey.model';
import { Questions } from '@shared/enums/questions.enum';
import { AIModelDependentMessage } from '@shared/utilities/string.utilities';

@Injectable()
export class SurveyAssistant {
  public interviews: { [questionKey: string]: InterviewData } = {};
  public rootAnswers: { [questionKey: string]: string } = {};

  public whyFinders: { [questionKey: string]: WhyFinderRequestData } = {};
  public whyFinderTargetAnswers: { [questionKey: string]: string } = {};
  public whyFinderMetaData: { [questionKey: string]: any } = {};

  constructor(
    private pa: PlayerApi,
    readonly tt: TranslateText,
  ) {}

  public reset() {
    this.interviews = {};
    this.rootAnswers = {};

    this.whyFinders = {};
    this.whyFinderTargetAnswers = {};
    this.whyFinderMetaData = {};
  }

  public initInterviewer(
    question: QuestionData,
    rootQuestion: QuestionData,
    answer: string,
    language: string,
    messages: InterviewMessageData[] = [],
    ask: boolean,
  ) {
    this.rootAnswers[question.$key] = answer;
    this.interviews[question.$key] = {
      temperature: 0.7,
      messages: (messages || []).slice(),
      rootQuestion: this.parseRootQuestionData(rootQuestion, answer),
      language,
      maxQuestions: question?.interviewer?.maxQuestions,
    } as InterviewData;

    return ask && question && rootQuestion && language
      ? this.pa.post('surveyassistant/ai/rootCause/messages', this.interviews[question.$key]).pipe(
          timeout(300000),
          catchError((error: any) => throwError(error)),
          tap((response) => {
            const message = response?.[0]?.message;
            if (message) {
              this.interviews[question.$key].messages.push({
                role: message.role,
                message: message.content,
              });
            }
          }),
        )
      : of(null);
  }

  public nextInterviewQuestion(question: QuestionData, userMessage) {
    if (this.interviews[question.$key] && userMessage) {
      this.interviews[question.$key].messages?.push(userMessage);

      return this.pa.post('surveyassistant/ai/rootCause/messages', this.interviews[question.$key]).pipe(
        timeout(300000),
        catchError((error: any) => throwError(error)),
        tap((response) => {
          const message = response?.[0]?.message;
          if (message) {
            this.interviews[question.$key].messages.push({
              role: message.role,
              message: message.content,
            });
          }
        }),
      );
    } else {
      return of(null);
    }
  }

  public getSummary(question: QuestionData, defaultLanguage: string) {
    if (this.interviews[question.$key]) {
      const summary = JSON.parse(JSON.stringify(this.interviews[question.$key]));
      summary.language = defaultLanguage;

      return this.pa.post('surveyassistant/ai/rootCause/summary', summary).pipe(
        timeout(300000),
        catchError((error: any) => throwError(error)),
      );
    } else {
      return of(null);
    }
  }

  public initWhyFinder(
    question: QuestionData,
    targetQuestion: QuestionData,
    answer: string,
    language: string,
    messages: InterviewMessageData[] = [],
    ask: boolean,
    metaData?: any,
  ) {
    const parsedTargetQuestion = this.parseRootQuestionData(targetQuestion, answer);
    this.whyFinderTargetAnswers[question.$key] = answer;
    this.whyFinders[question.$key] = {
      temperature: 0.7,
      modelKey: 'vertexai-claude-3-5-sonnet@20240620',
      secondaryModelKey: 'gpt-4o-latest',
      messages: (messages || []).slice(),
      parameters: {
        system: [
          language,
          parsedTargetQuestion?.type === 'nps' ? 'a score' : 'answer',
          parsedTargetQuestion?.answer,
          parsedTargetQuestion?.type,
          parsedTargetQuestion?.title,
          (question?.whyFinder?.maxQuestions || 3).toString(),
        ],
      },
    } as WhyFinderRequestData;
    this.whyFinderMetaData[question.$key] = metaData;

    return ask && question && targetQuestion && language && answer != null
      ? this.pa
          .postAIrequest(
            'surveyassistant/ai/esko/whyFinder',
            this.whyFinders[question.$key],
            this.whyFinderMetaData[question.$key],
          )
          .pipe(
            timeout(300000),
            catchError((error: any) => throwError(error)),
            tap((response) => {
              const message = AIModelDependentMessage(response);
              if (message) {
                this.whyFinders[question.$key].messages.push({
                  role: 'assistant',
                  message: message,
                });
              }
            }),
          )
      : of(null);
  }

  public nextWhyFinderQuestion(question: QuestionData, userMessage) {
    if (this.whyFinders[question.$key] && userMessage) {
      this.whyFinders[question.$key].messages?.push(userMessage);

      return this.pa
        .postAIrequest(
          'surveyassistant/ai/esko/whyFinder',
          this.whyFinders[question.$key],
          this.whyFinderMetaData[question.$key],
        )
        .pipe(
          timeout(300000),
          catchError((error: any) => throwError(error)),
          tap((response) => {
            const message = AIModelDependentMessage(response);
            if (message) {
              this.whyFinders[question.$key].messages.push({
                role: 'assistant',
                message: message,
              });
            }
          }),
        );
    } else {
      return of(null);
    }
  }

  public getWhyFinderSummary(
    question: QuestionData,
    defaultLanguage: string,
    additionalMessages?: InterviewMessageData[],
  ) {
    if (this.whyFinders[question.$key]) {
      const summary = JSON.parse(JSON.stringify(this.whyFinders[question.$key]));
      summary.parameters.system = [defaultLanguage];
      summary.parameters.function = [defaultLanguage];
      summary.functionName = 'getWhyFinderInterviewProperties';
      summary.modelKey = 'gpt-4o-mini';
      if (additionalMessages) {
        summary.messages = summary.messages.concat(additionalMessages);
      }

      return this.pa
        .postAIrequest('surveyassistant/ai/esko/whyFinderSaveData', summary, this.whyFinderMetaData[question.$key])
        .pipe(timeout(300000));
    } else {
      return of(null);
    }
  }

  public getWhyFinderFinishButton(question: QuestionData) {
    if (this.whyFinders[question.$key]) {
      const data = {
        temperature: 0.7,
        modelKey: 'gpt-4o-mini',
        messages: [{ role: 'user', message: JSON.stringify(this.whyFinders[question.$key]?.messages) }],
        functionName: 'getWhyFinderFinishButton',
      };

      return this.pa
        .postAIrequest('surveyassistant/ai/esko/whyFinderFinishButton', data, this.whyFinderMetaData[question.$key])
        .pipe(
          timeout(300000),
          catchError((error: any) => throwError(error)),
        );
    } else {
      return of(null);
    }
  }

  public parseRootQuestionData(rootQuestion, rawAnswer): RootQuestionData {
    const title: string = this.tt.transform(rootQuestion.title, `title-${rootQuestion?.$key}`);
    const parseChoice: (choice: string) => string = (choice) => {
      const splittedChoice = choice?.split('=');
      const choiceKey = splittedChoice?.[0];
      return (
        (this.tt.transform(
          rootQuestion?.choiceList?.find((c) => choiceKey === c?.$key),
          `choice-${rootQuestion?.$key}-${choiceKey}`,
        ) || '') + (splittedChoice?.[1] ? `=${splittedChoice[1]}` : '')
      );
    };
    const answer: string = Questions.isNps(rootQuestion)
      ? Math.round(Number(rawAnswer))
      : Questions.isChoice(rootQuestion) || rootQuestion?.type === Questions.INPUT_DROPDOWN
        ? rawAnswer
            ?.split(';')
            ?.map((a) => parseChoice(a))
            ?.join(',')
        : rawAnswer;
    const type: string = Questions.isNps(rootQuestion) ? 'nps' : 'other';
    const answerType: string = type;

    return { title, answer, type, answerType } as RootQuestionData;
  }
}
