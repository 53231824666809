/**
 * Loader to load data from release data.
 *
 * @unstable
 */

import { Observable, of, ReplaySubject, switchMap } from 'rxjs';

import { catchError, map, shareReplay } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { LoaderInterface } from '@shared/models/loader.model';

@Injectable()
export class ReleaseLoader implements LoaderInterface {
  private rootPath$ = new ReplaySubject<string>(1);

  private cacheMap = new Map<string, Observable<any>>();

  constructor(private http: HttpClient) {}

  setRootPath(path: string) {
    this.rootPath$.next(path.replace(/\/?$/, '/'));
  }

  fetchLocale(lcid: string): Observable<any> {
    if (this.cacheMap.has(lcid)) {
      return this.cacheMap.get(lcid);
    } else {
      const revision = window.ZEF ? window.ZEF.revision : Math.random().toString();

      const locale = this.rootPath$.pipe(
        switchMap((prefix) => this.http.get<{ app: any }>(`${prefix}${lcid}.json?${revision}`)),
        map((data) => data?.app || {}),
        catchError(() => of({})),
        shareReplay(1),
      );

      this.cacheMap.set(lcid, locale);

      return locale;
    }
  }
}
