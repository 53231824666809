import { environment } from '@env/environment';

import { BehaviorSubject, combineLatest, merge, Observable, of } from 'rxjs';
import {
  debounceTime,
  delay,
  distinctUntilChanged,
  filter,
  last,
  map,
  mapTo,
  shareReplay,
  skip,
  switchMap,
  take,
  takeUntil,
} from 'rxjs/operators';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';

import { SurveyStore } from '@player/shared/services/survey-store.service';
import { SharesManager } from '@player/shared/services/shares-manager.service';
import { LanguageManager } from '@player/shared/services/language-manager.service';
import { ContentAnimations } from '@player/shared/animations/content-state.anim';
import { CardScroll, SurveyAnimations } from '@player/shared/animations/survey-state.anim';
import { PlayerAnswer, PlayerAnswers, PlayerOutcome, PlayerState } from '@player/shared/models/player.model';

import { WaitOut } from '@shared/animations/fade-in-out.anim';
import { Outcomes } from '@shared/enums/outcomes.enum';
import { ViewSize } from '@shared/enums/view-size.enum';
import { TeamData } from '@shared/models/account.model';
import { Constants } from '@shared/enums/constants.enum';
import { Questions } from '@shared/enums/questions.enum';
import { LanguagesData } from '@shared/models/locale.model';
import {
  DesignData,
  OutcomeData,
  QuestionData,
  ReleaseData,
  SharingData,
  SurveyData,
  SurveyScoring,
  TriggerData,
  ViewState,
} from '@shared/models/survey.model';
import { PropertyStore } from '@shared/services/property-store.service';
import { LifecycleHooks } from '@shared/services/lifecycle-hooks.service';
import { PlayerApi } from '@player/shared/services/player-api.service';
import { isArrayShallowEqual } from '@shared/utilities/array.utilities';
import { CommunicationManager } from '@player/shared/services/communication-manager.service';
import { SurveyAssistant } from '@player/shared/services/survey-assistant.service';
import { NgScrollbar } from 'ngx-scrollbar';
import { getLastValue } from '@shared/operators/share-ref.operator';
import { TranslateText } from '@player/shared/pipes/translate-text.pipe';
import type { SurveyStartConfig } from 'zeffi/lib/models/survey-data.model';

type SizeError = 'rotate' | 'resize' | void;

@Component({
  selector: 'survey-view',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss'],
  providers: [LifecycleHooks, SurveyStore, SharesManager, TranslateText, SurveyAssistant],
  animations: [SurveyAnimations, ContentAnimations, CardScroll, WaitOut],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SurveyView implements OnInit, OnDestroy {
  readonly Outcomes = Outcomes;
  readonly Questions = Questions;
  readonly states = ViewState;

  public started: boolean = false;
  public showMenu: boolean = false;
  public waitNext: string = '';

  readonly sizeError = new BehaviorSubject<SizeError>(void 0);
  readonly bottomVisible = new BehaviorSubject<boolean>(true);
  readonly welcomeCardShown = new BehaviorSubject<boolean>(false);
  readonly reviewMode = new BehaviorSubject<boolean>(false);
  readonly reviewInit = new BehaviorSubject<string>('');

  readonly cardAnimation = new BehaviorSubject<boolean>(false);
  readonly disableAnimation = new BehaviorSubject<boolean>(true);

  readonly isLastQuestion = combineLatest([this.ss.viewState, this.ss.activeIdx, this.ss.cardQuestions]).pipe(
    map(([view, idx, questions]) => view === ViewState.Questions && idx >= questions.length - 1),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  readonly showLanguageSelector = combineLatest([this.ss.design, this.ss.languages]).pipe(
    map(([design, languages]) => design.sidebar.selectLanguage && Object.keys(languages || {}).length > 1),
    distinctUntilChanged(),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  readonly showPrivacyPolicy = combineLatest([this.ss.viewState, this.ss.survey]).pipe(
    map(([viewState, surveyData]) => viewState === ViewState.Welcome && surveyData.policy),
    distinctUntilChanged(),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  readonly isRequired = combineLatest([this.ss.activeQuestion, this.ss.answers]).pipe(
    map(
      ([question, answers]) =>
        !!(question && question.required && (answers[question.$key] == null || answers[question.$key] === '')),
    ),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  readonly showIsRequired = combineLatest([
    this.isRequired,
    this.reviewMode,
    this.reviewInit,
    this.ss.viewState,
    this.ss.answers,
  ]).pipe(
    map(
      ([required, reviewMode, reviewInit, state]) =>
        state === ViewState.Questions && required && (reviewMode || !!reviewInit),
    ),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  readonly hasButton = combineLatest([
    this.ss.viewState,
    this.ss.activeIdx,
    this.ss.survey,
    this.ss.cardQuestions,
    this.ss.answers,
    this.isLastQuestion,
  ]).pipe(
    map(
      ([view, idx, survey, questions, answers, isLast]) =>
        view !== ViewState.Questions ||
        !survey.autojump ||
        !questions[idx] ||
        isLast ||
        !Questions.jumping(questions[idx], answers[questions[idx].$key]),
    ),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  readonly buttonWrapperItems = combineLatest([
    this.ss.survey,
    this.ss.cardQuestions,
    this.ss.answers,
    this.reviewMode,
  ]).pipe(
    map(([survey, questions, answers, review]) =>
      questions
        .filter(
          (question, index) =>
            !survey.autojump ||
            questions.length - 1 === index ||
            !Questions.jumping(question, answers[question.$key]) ||
            (review && question.required),
        )
        .filter(
          (question) =>
            question?.type !== Questions.ESKO_WHY_FINDER &&
            (question?.type !== Questions.AI_INTERVIEWER ||
              (answers[question.$key]?.match(/Interviewee/g) || []).length > 2),
        )
        .map(({ $key }) => $key),
    ),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  readonly requiredUnanswered = combineLatest([this.ss.normalQuestions, this.ss.allAnswers]).pipe(
    map(([questions, answers]) =>
      questions.filter(({ required, $key }) => required && (answers[$key] == null || answers[$key] === '')),
    ),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  readonly showUnansweredLabel = combineLatest([this.requiredUnanswered, this.reviewInit, this.ss.activeKey]).pipe(
    map(([questions, reviewInit, key]) => !!reviewInit && reviewInit === key && !!questions.length),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  readonly isReviewLast = combineLatest([
    this.ss.activeKey,
    this.ss.cardQuestions,
    this.ss.seenQuestions,
    this.isLastQuestion,
    this.requiredUnanswered,
  ]).pipe(
    map(([key, questions, seen, isLast, unanswered]) => {
      if (isLast) {
        return true;
      }

      const idx = questions.findIndex(({ $key }) => $key === key);
      const nextQuestions = questions.slice(idx + 1);
      const unseen = questions.filter(({ $key }) => !seen.some((seenKey) => seenKey === $key));
      const nextUnseen = unseen.find((item) => nextQuestions.some(({ $key }) => item.$key === $key));
      const nextUnanswered = unanswered.find((item) => nextQuestions.some(({ $key }) => item.$key === $key));
      return !nextUnseen && !nextUnanswered;
    }),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  readonly buttonItems = combineLatest([
    this.ss.survey,
    this.ss.cardQuestions,
    this.buttonWrapperItems,
    this.ss.answers,
  ]).pipe(
    map(([survey, questions, items, answers]) =>
      survey.autojump
        ? questions
            .filter(
              (question, index) =>
                questions.length - 1 === index || !Questions.jumping(question, answers[question.$key]),
            )
            .map(({ $key }) => $key)
        : items,
    ),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  readonly floatingCtaButton = merge(
    combineLatest([this.bottomVisible, this.ss.isMobile]).pipe(map(([visible, isMobile]) => isMobile && !visible)),
    this.ss.viewState.pipe(distinctUntilChanged(), mapTo(false)),
  ).pipe(distinctUntilChanged(), shareReplay({ refCount: true, bufferSize: 1 }));

  readonly floatingButtonContainer = combineLatest([
    this.hasButton,
    this.floatingCtaButton,
    this.ss.viewState,
    this.bottomVisible,
    this.ss.playerDimensions,
    this.ss.isMobile,
  ]).pipe(
    map(([hasButton, floatingCtaButton, viewState, bottomVisible, { scrollable }, isMobile]) => {
      // console.warn(hasButton, floatingCtaButton, bottomVisible, isMobile);
      if (viewState === ViewState.Welcome) {
        return isMobile ? hasButton && !bottomVisible : false;
      } else {
        // console.warn(scrollable, hasButton, floatingCtaButton, result);

        return scrollable || !hasButton || !floatingCtaButton;
      }
    }),
  );

  readonly showFloatingNavigation = combineLatest([this.hasButton, this.floatingCtaButton, this.ss.viewState]).pipe(
    map(([, , viewState]) => {
      return viewState !== ViewState.Welcome;
    }),
  );

  readonly showFloatingContainerBackground = combineLatest([
    this.hasButton,
    this.floatingCtaButton,
    this.ss.viewState,
  ]).pipe(
    map(([, , viewState]) => {
      return viewState === ViewState.Welcome;
    }),
  );

  readonly button: Observable<{ text: string; translation: string }> = combineLatest([
    combineLatest([this.ss.viewState, this.ss.survey, this.ss.thanksOutcome, this.ss.activeKey]),
    combineLatest([this.isLastQuestion, this.reviewInit, this.reviewMode, this.isReviewLast]),
  ]).pipe(
    map(([[viewState, survey, outcome, key], [isLast, reviewInit, review, isReviewLast]]) => {
      let text = '';
      let translation = '';

      if (viewState === ViewState.Questions) {
        if (isLast || review || reviewInit) {
          if (key && reviewInit === key) {
            text = 'Review';
            translation = 'reviewButton';
          } else {
            text = isReviewLast || isLast ? 'Finish' : 'Ok';
            translation = isReviewLast || isLast ? 'finishButton' : 'okButton';
          }
        } else {
          text = 'Ok';
          translation = 'nextButton';
        }
      } else if (viewState === ViewState.Outcomes && survey.results === Outcomes.GOODBYE) {
        if (outcome && outcome.link) {
          text = 'Open link';
          translation = 'callToAction';
        }
      } else if (viewState === ViewState.Welcome) {
        const fallback = { en: 'Start', fi: 'Aloita', sv: 'Starta' }[this.lm.currentLanguage] || 'Start';
        text = survey.start || fallback;
        translation = 'startSurvey';
      }

      return { text, translation };
    }),
    delay(100),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  readonly showButtonContainer = combineLatest([
    this.ss.survey,
    this.ss.isMobile,
    this.ss.viewState,
    this.bottomVisible,
    this.showIsRequired,
    this.button,
    this.ss.cardQuestions,
  ]).pipe(
    map(([survey, mobile, viewState, bottomVisible, isRequired, button, questions]) => {
      const showingQuestionsOnMobile = mobile && viewState === ViewState.Questions && !!questions?.length;
      const showingWelcomeOnMobile = mobile && viewState === ViewState.Welcome;
      const showingTopOnNonOutcome = !bottomVisible && viewState !== ViewState.Outcomes;
      const showingTopOnGoodbye = !bottomVisible && survey.results === Outcomes.GOODBYE;

      const showContainer =
        showingQuestionsOnMobile || showingWelcomeOnMobile || showingTopOnNonOutcome || showingTopOnGoodbye;
      const isButtonOk = !!button?.text && !!button?.translation;

      const show = !isRequired && isButtonOk && showContainer;
      // console.warn(show, mobile, viewState, bottomVisible, isRequired);

      return show;
    }),
    distinctUntilChanged(),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  readonly checkedIndex = this.ss.activeIdx.pipe(
    distinctUntilChanged(),
    delay(1),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  readonly hasMultiple = combineLatest([
    this.sizeError,
    this.ss.viewState,
    this.ss.survey,
    this.ss.scoredOutcomes,
    this.ss.cardQuestions,
  ]).pipe(
    map(([sizeWarning, viewState, survey, outcomes, questions]) => {
      if (sizeWarning) {
        return false;
      }

      if (viewState === ViewState.Questions) {
        return !!questions.length;
      }

      return viewState === ViewState.Outcomes && survey.results === Outcomes.OUTCOME && outcomes.length > 1;
    }),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  readonly hasThankYouProperties = combineLatest([
    this.ss.survey,
    this.ss.thanksOutcome,
    this.ss.scoredOutcomes,
    this.ss.viewState,
  ]).pipe(
    map(([survey, thankYou, scoredOutcomes]) =>
      survey?.results === Outcomes.GOODBYE
        ? !!thankYou &&
          !!(
            thankYou.title ||
            thankYou.content ||
            thankYou.image?.url ||
            thankYou.cta?.text ||
            thankYou.videoEmbed?.videoId ||
            window.ZEF?.restartDelay ||
            window.ZEF?.restartInactive
          )
        : scoredOutcomes?.length > 0,
    ),
  );

  readonly scrollOffset = combineLatest([this.ss.viewSize, this.hasMultiple]).pipe(
    map(([size, hasMultiple]) => {
      if (size === ViewSize.Tiny || size === ViewSize.ExtraSmall) {
        return hasMultiple ? { bottom: 88, top: 22 } : { bottom: 64, top: 22 };
      } else if (size === ViewSize.Small) {
        return hasMultiple ? { bottom: 120, top: 44 } : { bottom: 72, top: 44 };
      } else if (size === ViewSize.Medium || size === ViewSize.Large || size === ViewSize.ExtraLarge) {
        return hasMultiple ? { bottom: 160, top: 48 } : { bottom: 112, top: 48 };
      } else {
        return { bottom: 0, top: 0 };
      }
    }),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  readonly scrollMargin = this.ss.viewSize.pipe(
    map((size) => {
      if (size === ViewSize.Tiny || size === ViewSize.ExtraSmall) {
        return 8;
      } else if (size === ViewSize.Small) {
        return 16;
      } else if (size === ViewSize.Medium || size === ViewSize.Large || size === ViewSize.ExtraLarge) {
        return 16;
      } else {
        return 0;
      }
    }),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  readonly touchScreen = window.ontouchstart !== undefined;

  @Input() isAnonymous = false;
  @Input() checkSize = true;
  @Input() teamKey: string | null = null;
  @Input() surveyKey: string | null = null;
  @Input() releaseUrl: string | null = null;
  @Input() releaseLocation: string | null = null;

  @Input() disabledInitialFocus?: boolean;

  @Input() set answerData(answers: PlayerAnswers | null) {
    this.ss.answers.next(answers || {});
  }

  @Input() set playerState(state: PlayerState) {
    this.setState(state);
  }

  @Input() set teamData(team: TeamData | null) {
    this.ss.team.next(team);
  }

  @Input() set surveyData(survey: SurveyData | null) {
    this.ss.survey.next(survey);
  }

  @Input() set designData(design: DesignData | null) {
    this.ss.design.next(design);
  }

  @Input() set releaseData(release: ReleaseData | null) {
    this.ss.release.next(release);
  }

  @Input() set sharingData(sharing: SharingData | null) {
    this.ss.sharing.next(sharing);
  }

  @Input() set scoringData(scoring: SurveyScoring | null) {
    this.ss.scoring.next(scoring);
  }

  @Input() set outcomesData(outcomes: OutcomeData[] | null) {
    this.ss.outcomes.next(outcomes);
  }

  @Input() set questionsData(questions: QuestionData[] | null) {
    this.ss.questions.next(questions);
  }

  @Input() set triggerData(triggers: TriggerData[] | null) {
    this.ss.triggers.next(triggers);
  }

  @Input() set languagesData(languages: LanguagesData /* | null*/) {
    this.ss.languages.next(languages);
  }

  @Input() set queryLanguage(lcid: string) {
    this.lm.paramsLanguage = lcid || null;
  }

  @Input() set selectedLanguage(lcid: string) {
    if (lcid) {
      this.lm.setLanguage(lcid);
    }
  }

  @Input() set previewMode(mode: string) {
    const height =
      mode === 'mobilePortrait'
        ? 640
        : mode === 'mobileLandscape'
          ? 400
          : mode === 'tabletLandscape'
            ? 768
            : mode === 'tabletPortrait'
              ? 1024
              : 0;
    this.setViewRects(height);
  }

  @Input() set surveyStartConfig(startConfig: SurveyStartConfig) {
    if (!startConfig) {
      return;
    }

    if (startConfig.restart) {
      this.onResetAnswers();
      this.restartState();
    }
  }

  @Output() showResults = new EventEmitter<void>();
  @Output() resetAnswers = new EventEmitter<void>();
  @Output() showAccessibilityBanner = new EventEmitter<void>();
  @Output() hideAccessibilityBanner = new EventEmitter<void>();

  @Output() activeChanged = new EventEmitter<PlayerAnswer>();
  @Output() answerChanged = new EventEmitter<PlayerAnswer>();

  @Output() resultsChanged = new EventEmitter<PlayerOutcome[]>();

  @Output() playerStateChange: Observable<PlayerState> = combineLatest([this.ss.viewState, this.ss.activeKey]).pipe(
    distinctUntilChanged(isArrayShallowEqual),
    map(([state, key]) => ({ view: state, $key: key })),
  );

  public cardRect?: ClientRect;
  public isLanguageBoxOpen: boolean = false;

  private cardContainerEl?: HTMLElement;

  private destroyed?: boolean;

  private firstInit?: boolean;

  private settingState?: boolean;

  @ViewChild('surveyView', { read: ElementRef, static: true })
  surveyView?: ElementRef<HTMLElement>;

  private sb?: NgScrollbar;

  @ViewChild(NgScrollbar)
  set scrollbar(sb: NgScrollbar) {
    if (sb) {
      this.sb = sb;
      this.zone.runOutsideAngular(() => {
        this.sb.verticalScrolled
          .pipe(debounceTime(1), takeUntil(this.hooks.destroy))
          .subscribe(() => this.checkPsbScroll());
      });
    }
  }

  @ViewChild('cardContainer', { read: ElementRef })
  set cardContainer(cardContainer: ElementRef<HTMLElement>) {
    this.cardContainerEl = cardContainer?.nativeElement;
    this.cardRect = this.cardContainerEl?.getBoundingClientRect();
  }

  @HostListener('window:resize.p')
  onResize = () => {
    this.setViewRects();
  };

  constructor(
    readonly cdRef: ChangeDetectorRef,
    readonly hooks: LifecycleHooks,
    readonly sa: SurveyAssistant,
    readonly sm: SharesManager,
    readonly lm: LanguageManager,
    readonly ps: PropertyStore,
    readonly ss: SurveyStore,
    readonly zone: NgZone,
    readonly router: Router,
    private pa: PlayerApi,
    @Optional() private cm?: CommunicationManager,
  ) {}

  ngOnInit(): void {
    this.ss.viewState
      .pipe(
        filter((view) => view !== ViewState.Loading),
        takeUntil(this.hooks.destroy),
      )
      .subscribe(() => this.ss.onPlayerInactivity(() => this.onResetAnswers()));

    this.onResize();

    this.ss.scoredOutcomes.pipe(skip(1), takeUntil(this.hooks.destroy)).subscribe((results: PlayerOutcome[]) => {
      this.resultsChanged.emit(results);
      this.cm?.emitOutcomeEvent(results);
    });

    this.cm?.restart$.pipe(takeUntil(this.hooks.destroy)).subscribe(() => this.onResetAnswers());

    this.ss.seenQuestions.pipe(takeUntil(this.hooks.destroy)).subscribe();

    this.pa.uploadTrigger$
      .pipe(
        switchMap((key) => {
          const upload = this.pa.getFileUpload(key);

          if (!upload) {
            return of(void 0);
          }

          return combineLatest([
            this.ss.cardQuestions.pipe(map((questions) => questions.find((question) => question.$key === key))),
            upload.progress$.pipe(last()),
          ]).pipe(
            take(1),
            map(([question, progress]) =>
              progress === 1
                ? {
                    item: question,
                    value: upload.file.name,
                  }
                : void 0,
            ),
          );
        }),
        takeUntil(this.hooks.destroy),
      )
      .subscribe((answer?: PlayerAnswer) => {
        if (answer) {
          this.onAnswerChanged(answer);
        }
      });
  }

  ngOnDestroy(): void {
    this.destroyed = true;

    this.lm.reset();
    this.ps.destroy();
    this.hideAccessibilityBanner.next();
  }

  setViewRects(height?: number): void {
    this.cardRect = this.cardContainerEl?.getBoundingClientRect();
    const surveyViewSize = this.surveyView?.nativeElement.getBoundingClientRect();
    if (height > 0) {
      surveyViewSize.height = Math.min(height, surveyViewSize.height);
    }
    this.ss.viewRect$.next(surveyViewSize);
    this.checkWindowSize();
  }

  onSurveyAnimationDone(): void {
    this.disableAnimation.next(false);
    this.cardAnimation.next(false);

    setTimeout(() => this.checkPsbScroll());
    this.cdRef.markForCheck();
  }

  onScrollAnimationDone(): void {
    if (!this.destroyed) {
      if (this.sb) {
        this.sb.update();
      }

      this.cardAnimation.next(false);

      setTimeout(() => this.checkPsbScroll());
      this.cdRef.detectChanges();
    }
  }

  checkWindowSize(): void {
    const activeElement = document.activeElement;
    const inputs = ['input', 'select', 'button', 'textarea'];
    const active = activeElement ? activeElement.tagName.toLowerCase() : '';

    if (inputs.indexOf(active) === -1 && this.surveyView) {
      const rect = this.surveyView.nativeElement.getBoundingClientRect();
      const isResized = rect.width > 0 && rect.height > 0;

      let warning =
        rect.width > rect.height && rect.height < Constants.PLAYER_MIN_HEIGHT ? 'rotate' : (void 0 as SizeError);
      warning = !warning && rect.width < Constants.PLAYER_MIN_WIDTH - 1 ? 'resize' : warning;

      if (this.checkSize && isResized && warning !== this.sizeError.getValue()) {
        this.zone.run(() => this.sizeError.next(warning));
      }
    }
  }

  public init(restart?: boolean): void {
    combineLatest([this.ss.languages, this.ss.survey, this.ss.design])
      .pipe(take(1), debounceTime(1))
      .subscribe(([locales, survey, design]) => {
        this.lm.autodetectLanguage = !!design.sidebar?.selectLanguage;
        this.lm.init(locales, survey.language, this.releaseLocation);
        this.sm.init(this.teamKey, this.surveyKey, this.releaseUrl);
        this.ps.init();

        if ((restart || this.ss.viewState.getValue() === ViewState.Loading) && !this.settingState) {
          this.restartState();
        }

        this.checkWindowSize();
      });

    if (!this.firstInit) {
      this.firstInit = true;

      combineLatest([
        this.ss.languages,
        this.ss.survey.pipe(
          map((survey) => survey && survey.language),
          distinctUntilChanged(),
        ),
      ])
        .pipe(skip(1), takeUntil(this.hooks.destroy))
        .subscribe(([languages, surveyLanguage]) => {
          this.lm.updateLocales(languages, surveyLanguage);
        });

      this.cm?.emitStartEvent();
    }
  }

  private setState(state: PlayerState): void {
    if (!state) {
      return;
    }

    const oldState = this.ss.viewState.getValue();
    const oldKey = this.ss.activeKey.getValue();

    if (state.view === oldState && (oldState === ViewState.Welcome || state.$key === oldKey)) {
      return;
    }

    this.settingState = true;

    // wait two loops
    setTimeout(() =>
      setTimeout(() => {
        this.ss.overrideKey.next(state.$key);
        this.ss.activeKey.next(state.$key);
        this.setViewState(state.view);

        this.cdRef.markForCheck();
      }),
    );
  }

  private setViewState(state: ViewState): void {
    this.disableAnimation.next(true);
    this.ss.viewState.next(ViewState.Loading);

    setTimeout(() => {
      this.ss.viewState.next(state);
      this.cardAnimation.next(false);

      if (!this.destroyed) {
        this.cdRef.detectChanges();
      }

      setTimeout(() => {
        this.settingState = false;
      });
    });
  }

  public onNextQuestion(): void {
    combineLatest([
      combineLatest([this.ss.activeKey, this.ss.cardQuestions, this.ss.seenQuestions]),
      combineLatest([this.isLastQuestion, this.requiredUnanswered, this.reviewMode, this.reviewInit]),
    ])
      .pipe(take(1))
      .subscribe(([[key, questions, seen], [isLast, unanswered, review, reviewInit]]) => {
        const unseen = questions.filter(({ $key }) => !seen.some((seenKey) => seenKey === $key));

        if (reviewInit) {
          const firstUnseen = questions.indexOf(unseen[0]);
          const firstUnAnswered = questions.indexOf(unanswered[0]);
          const next = Math.min(
            firstUnseen === -1 ? firstUnAnswered : firstUnseen,
            firstUnAnswered === -1 ? firstUnseen : firstUnAnswered,
          );

          if (next === -1) {
            this.onShowResults();
          } else {
            this.reviewInit.next('');
            this.reviewMode.next(true);
            this.setActiveIdx(next);
          }
        } else if (review) {
          const idx = questions.findIndex(({ $key }) => $key === key);
          const nextQuestions = questions.slice(idx + 1);
          const nextUnseen = unseen.find((item) => nextQuestions.some(({ $key }) => item.$key === $key));
          const nextUnanswered = unanswered.find((item) => nextQuestions.some(({ $key }) => item.$key === $key));

          const nextQuestion = nextQuestions.find(
            ({ $key }) => $key === (nextUnseen && nextUnseen.$key) || $key === (nextUnanswered && nextUnanswered.$key),
          );

          if (nextQuestion) {
            this.reviewInit.next('');
            this.ss.activeKey.next(nextQuestion.$key);
          } else if (unanswered.length) {
            this.ss.initSeen.next();
            this.reviewInit.next(key);
          } else {
            this.onShowResults();
          }
        } else if (isLast && unanswered.length) {
          this.ss.initSeen.next();
          this.reviewInit.next(key);
        } else if (isLast) {
          this.onShowResults();
        } else {
          this.changeIdx(1);
        }

        this.cdRef.markForCheck();
        this.cdRef.detectChanges();
      });
  }

  public changeIdx(change: number): void {
    this.ss.activeIdx.pipe(take(1)).subscribe((idx) => {
      if (!this.isLanguageBoxOpen) {
        this.setActiveIdx(idx + change);
      }
    });
  }

  public setActiveIdx(idx: number): void {
    combineLatest([this.ss.viewState, this.ss.cardQuestions, this.ss.scoredOutcomes, this.ss.activeIdx])
      .pipe(take(1))
      .subscribe(([view, questions, outcomes, activeIdx]) => {
        const source = view === ViewState.Outcomes ? outcomes : view === ViewState.Questions ? questions : [];
        idx = Math.max(0, Math.min(idx, source.length - 1));
        const item = source[idx];
        const next = ((oc): oc is PlayerOutcome => oc && oc.hasOwnProperty('item'))(item) ? item.item : item;
        const { scrollTop } = this.sb.viewport;

        if (view === ViewState.Questions && scrollTop > 0 && idx !== activeIdx) {
          this.sb.scrollTo({ top: 0, duration: 0 });
        }
        this.reviewInit.next('');
        this.ss.activeKey.next((next && next.$key) || '');
      });
  }

  public changeOutcome(index: number) {
    this.scrollOffset.pipe(take(1)).subscribe(({ top }) => {
      const element = document.getElementById(`outcome-${index}`);
      if (this.sb && element) {
        const posY = element.parentElement.parentElement.offsetTop;
        this.sb.scrollTo({ top: posY - top });
        // this.psd.scrollToElement(`#outcome-${index}`, -1 * top, 250);
      }
    });
  }

  public openMenu(): void {
    this.showAccessibilityBanner.next();

    this.showMenu = true;
  }

  public closeMenu(): void {
    this.hideAccessibilityBanner.next();

    this.showMenu = false;
  }

  checkPsbScroll(): void {
    combineLatest([this.scrollOffset, this.scrollMargin, this.ss.activeIdx, this.ss.viewState])
      .pipe(take(1))
      .subscribe(([{ bottom }, margin, idx, state]) => {
        if (!this.sb) {
          return;
        }

        const { contentHeight, clientHeight, scrollTop, nativeElement } = this.sb.viewport;
        const surveyScrollerEl: HTMLElement = nativeElement.querySelector('.z-cards-scroll');
        const scrollerHeight = surveyScrollerEl?.offsetHeight || 0;

        const bottomVisible =
          scrollerHeight === contentHeight
            ? // standalone player
              scrollerHeight - clientHeight - scrollTop < bottom
            : // editor preview
              scrollerHeight - contentHeight - scrollTop < bottom;

        // console.warn(bottomVisible, topVisible);
        if (this.bottomVisible.getValue() !== bottomVisible) {
          this.zone.run(() => {
            this.bottomVisible.next(bottomVisible);
            this.cdRef.markForCheck();
          });
        }

        if (state === ViewState.Outcomes) {
          this.calculateOutcomeCardHeights();
          const ratio = (scrollerHeight - clientHeight) / scrollerHeight;
          const cardScrollAreas = (this.ss.cardHeights.value || [])
            .map((height) => height + margin * 2)
            .reduce((totals, height, i) => {
              const from = totals[i - 1]?.[1] || 0;

              return [...totals, [Math.floor(from), Math.ceil(from + height * ratio)]];
            }, []);

          let index = cardScrollAreas.findIndex((area) => area[0] <= scrollTop && area[1] >= scrollTop);

          if (index === -1 && scrollTop) {
            index = cardScrollAreas.length - 1;
          }

          if (index !== idx) {
            this.zone.run(() => {
              this.setActiveIdx(index);
              this.cdRef.markForCheck();
            });
          }
        }
      });
  }

  public onViewResize({ dimensions: { width, height } }): void {
    this.ss.playerDimensions.next({ width, height });
    this.calculateOutcomeCardHeights();
    this.checkPsbScroll();
  }

  private calculateOutcomeCardHeights() {
    if (this.sb) {
      const { nativeElement } = this.sb.viewport;
      const outcomeContainerHeights = Array.from(nativeElement.querySelectorAll(`.z-card-outcome`)).map(
        (x: HTMLElement) => x.offsetHeight,
      );
      this.ss.cardHeights.next(outcomeContainerHeights);
    }
  }

  public onShowResults(): void {
    this.reviewMode.next(false);
    this.reviewInit.next('');
    this.setViewState(ViewState.Outcomes);
    this.setActiveIdx(0);
    this.showResults.emit();

    combineLatest([this.ss.answers, this.ss.normalQuestions, this.ss.scoredOutcomes])
      .pipe(take(1))
      .subscribe(([answers, questions, outcomes]) => {
        const progress = this.getProgress(answers, questions);

        this.cm?.emitCompleteEvent(progress, questions, answers, outcomes);
      });
  }

  public onShowQuestions(): void {
    this.setViewState(ViewState.Questions);
    this.setActiveIdx(0);
  }

  public onResetAnswers(): void {
    this.started = false;

    this.answerData = {};
    this.sa.reset();
    this.ss.hiddenAnswers.next({});
    this.ss.initSeen.next();
    this.ss.resetFunnel();

    this.ss.questions.next(getLastValue(this.ss.questions));

    this.resetAnswers.emit();

    this.restartState();
    this.cm?.emitRestartEvent();
  }

  public onAnswerChanged(answer: PlayerAnswer): void {
    combineLatest([this.ss.answers, this.ss.normalQuestions])
      .pipe(take(1))
      .subscribe(([answers, questions]) => {
        answers = { ...answers, [answer.item.$key]: answer.value };
        answer.progress = this.getProgress(answers, questions);

        this.answerData = answers;

        this.answerChanged.emit(answer);
        this.cm?.emitAnswerEvent(answer);
      });
  }

  onCtaButtonClick(): void {
    combineLatest([
      this.ss.viewState,
      this.isLastQuestion,
      combineLatest([
        this.ss.survey,
        this.ss.isFree,
        this.ss.thanksOutcome,
        this.ss.cardQuestions,
        this.ss.answers,
        this.ss.activeIdx,
      ]),
    ])
      .pipe(delay(1), take(1))
      .subscribe(([state, , [survey, isFree, outcome, questions, answers, idx]]) => {
        this.bottomVisible.next(true);

        if (state === ViewState.Questions) {
          const question = questions[idx];
          const isCheckbox = question.type === Questions.INPUT_CHECKBOX;
          const isJumping = Questions.jumping(question);

          if (!isJumping && question && (!question.required || isCheckbox) && answers[question.$key] === undefined) {
            this.onAnswerChanged({
              value: isCheckbox ? question.inputField?.value || 'false' : '',
              item: question,
            });
          }

          this.onNextQuestion();
        } else if (state === ViewState.Outcomes) {
          const url = isFree ? `https:${environment.wwwAddress}` : outcome && outcome.link;

          if (url) {
            window.open(url, '_blank');
          }
        } else if (state === ViewState.Welcome) {
          if (survey.welcome) {
            this.welcomeCardShown.next(true);
          }

          this.onShowQuestions();
        }
      });
  }

  onOutcomeArrowClick(): void {
    combineLatest([this.ss.activeIdx, this.ss.scoredOutcomes])
      .pipe(take(1))
      .subscribe(([idx, [outcome]]) => {
        if (idx === 0) {
          this.onShowQuestions();
        } else {
          this.sb.scrollTo({ top: 0, duration: 250 });
          this.ss.activeKey.next(outcome?.item?.$key);
        }
      });
  }

  private getProgress(answers: PlayerAnswers, questions: QuestionData[]): number {
    const progress = Object.keys(answers).length > 0 ? questions.map(({ $key }) => answers[$key] != null) : [];
    const answered = progress.filter(Boolean);

    return answered.length / progress.length;
  }

  private restartState(): void {
    this.reviewMode.next(false);
    this.reviewInit.next('');
    this.bottomVisible.next(true);
    this.welcomeCardShown.next(false);

    combineLatest([this.ss.survey, this.ss.cardQuestions, this.ss.overrideKey])
      .pipe(
        map(([{ welcome }, [question], override]) => ({
          view: welcome ? ViewState.Welcome : ViewState.Questions,
          key: override || (question && question.$key),
        })),
        delay(1),
        take(1),
      )
      .subscribe(({ view, key }) => {
        this.setViewState(view);

        if (view === ViewState.Questions) {
          this.ss.activeKey.next(key);
        }
      });
  }

  noop() {}

  trackBy$Key = (idx: number, question: QuestionData): string => question.$key;

  trackByScoredOutcome = (idx: number, scored: PlayerOutcome): string => scored.item.$key;

  flagClass(locale: string) {
    return locale && locale.length !== 2 ? 'zef-lang-other' : `zef-lang-${locale}`;
  }

  isFixedHeight(question: QuestionData) {
    return Questions.slider(question);
  }
}
